<template>
  <button
    :type="type"
    :disabled="disabled || loading"
    class="primary-btn"
    v-bind="$attrs"
  >
    <div v-if="loading">
      <Spinner class="mr-3" />
    </div>
    <div>
      <slot></slot>
    </div>
  </button>
</template>

<script>
import Spinner from "../../layouts/spinner.vue";

export default {
  components: { Spinner },
  name: "PrimaryButton",
  props: {
    type: {
      type: String,
      default: "submit",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.primary-btn[disabled] {
  filter: opacity(0.5);
}
.primary-btn {
  width: 100%;
  border-radius: 0.5rem;
  padding: 1.5rem 2rem;
  background-color: var(--primary-color);
  text-align: center;
  font-size: Max(1rem, 16px);
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0em;
  color: #fff;
  text-align: center;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.3s ease-in-out;
}
.primary-btn:hover {
  background-color: #01553f;
}
</style>
