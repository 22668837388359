<template>
  <Fragment>
    <BlockLoader v-if="isLoading" />
    <el-carousel trigger="click" v-else>
      <el-carousel-item
        v-for="item in campaignsList"
        :key="item.id"
        class="rounded-lg"
      >
        <div
          class="banner-img banner-big wow fadeIn animated f-none animated animated"
          style="visibility: visible"
        >
          <img
            :src="item.campaign_image || item.img"
            alt=""
            fetchpriority="high"
            class="w-full"
          />
          <!-- <div
              class="banner-text"
              v-campaign-insights:views="item.uuid"
              v-if="item.name"
            >
              <h4 class="mb-15 mt-40">{{ item.name }}</h4>
              <h1 class="fw-600 mb-20" v-html="item.caption"></h1>
              <a :href="item.url" target="_blank">
                Get in touch <i class="fi-rs-arrow-right"></i>
              </a>
            </div> -->
        </div>
        <a
          :href="item.url"
          class="carousel__link"
          rel="noopener,noreferrer"
          target="_blank"
        ></a>
      </el-carousel-item>
    </el-carousel>
  </Fragment>
</template>

<script setup>
import axios from "axios";
import BlockLoader from "@/components/static/BlockLoader.vue";

import { ref, watchEffect } from "vue";
import { useQuery } from "@tanstack/vue-query";
import { Fragment } from "vue-frag";

const campaignsList = ref([]);
const isLoading = ref(true);

const user = async () => {
  let res = await axios.get("campaigns/audiences/all");
  return res.data.data;
};

const { data } = useQuery({
  queryKey: ["headerCampaigns"],
  queryFn: user,
});

const GetCampaigns = async () => {
  let res = await axios.get(`campaigns/${data.value[1].uuid}`);
  isLoading.value = false;
  return res.data.data;
};

const campaignQuery = useQuery({
  queryKey: ["headercampaigns"],
  queryFn: GetCampaigns,
  keepPreviousData: true,
  // refetchInterval: 30000,
});

const carousel = ref([
  {
    id: 1,
    img: "https://res.cloudinary.com/dzkh9cyq4/image/upload/q_auto:low,f_auto/v1719235314/20240619_221532_tcwaui.jpg",
  },
  {
    id: 2,
    img: "https://res.cloudinary.com/dzkh9cyq4/image/upload/q_auto:low,f_auto/v1719235314/20240620_200932_mkyvq6.jpg",
  },
  {
    id: 3,
    img: "https://res.cloudinary.com/dzkh9cyq4/image/upload/q_auto:low,f_auto/v1719235313/20240619_221344_jedrxb.jpg",
  },
  {
    id: 4,
    img: "https://res.cloudinary.com/dzkh9cyq4/image/upload/q_auto:low,f_auto/v1719235313/20240619_221429_qgph6j.jpg",
  },
  {
    id: 5,
    img: "https://res.cloudinary.com/dzkh9cyq4/image/upload/q_auto:low,f_auto/v1719235313/20240619_221250_e4c4yf.jpg",
  },
  {
    id: 6,
    img: "https://res.cloudinary.com/dzkh9cyq4/image/upload/q_auto:low,f_auto/v1719235313/20240619_220949_bxbn3h.jpg",
  },
]);

// Function to shuffle array
function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

watchEffect(() => {
  if (campaignQuery.data) {
    const dealsArray = carousel.value || [];
    const queryResults = campaignQuery.data.value || [];

    campaignsList.value = shuffleArray([...dealsArray, ...queryResults]);
  }
});
</script>

<style scoped>
header.header .el-carousel {
  flex: 1;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.carousel__link {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: inline-block;
  height: 100%;
  width: 100%;
}

.banner-img {
  position: relative;
  clear: both;
  height: 400px;
}
.banner-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* .banner-img::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
} */
.banner-img .banner-text {
  position: absolute;
  top: 50%;
  z-index: 2;
  -webkit-transform: translateY(-48%);
  transform: translateY(-48%);
  padding: 1rem 1.5rem;
}
.banner-img .banner-text h4 {
  font-size: 10px;
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
  color: white;
}
.banner-img .banner-text h1 {
  font-size: 13px;
  font-weight: 800;
  color: white;
}

.banner-img .banner-text a {
  display: flex;
  align-items: self-start;
  column-gap: 0.25rem;
  font-size: 10px;
  font-weight: 600;
  color: white;
  margin-top: 0.25rem;
}

@media (min-width: 768px) {
  .banner-img .banner-text h4 {
    font-size: 16px;
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .banner-img .banner-text {
    padding: 1rem 2rem;
  }
  .banner-img .banner-text h1 {
    font-size: 1.6rem;
  }
  .banner-img .banner-text a {
    font-size: 16px;
    margin-top: 0.5rem;
  }
}

@media (min-width: 1024px) {
  .banner-img .banner-text {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 20px 30px;
  }
  .banner-img .banner-text h4 {
    font-size: 18px;
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .banner-img .banner-text {
    padding: 2.5rem 3rem;
  }
  .banner-img .banner-text h1 {
    font-size: 2.5rem;
  }
  .banner-img .banner-text a {
    font-size: 18px;
    margin-top: 1rem;
  }
}

@media screen and (max-width: 769px) {
  img {
    width: 100%;
    height: 300px;
  }

  .el-carousel {
    height: min(35vh, 400px);
  }

  .el-carousel .el-carousel__container {
    height: 100% !important;
  }
}
@media screen and (max-width: 579px) {
  img {
    width: 100%;
    height: 230px;
  }
}
@media (min-width: 769px) {
  img {
    height: 400px;
  }
}
</style>

<style>
header.header .el-carousel .el-carousel__container {
  height: 100% !important;
}

@media screen and (max-width: 769px) {
  header.header .banner-img {
    height: 100% !important;
  }
}
</style>
