<template>
  <section class="spend" :class="{ active: cookie }">
    <div>
      <slot></slot>
      <img
        src="@/assets/icons/cancel.svg"
        loading="lazy"
        height="32"
        width="32"
        alt=""
        @click="($store.state.saveOn = false), close()"
      />
    </div>
  </section>
</template>

<script setup>
import { ref } from "vue";

const cookie = ref(false);
setTimeout(() => {
  cookie.value = true;
}, 300);
const close = () => {
  setTimeout(() => {
    cookie.value = false;
  }, 300);
};
</script>

<style scoped>
.spend {
  background-color: white;
  width: 100%;
  max-height: 60vh;
  height: auto;
  overflow: auto;
  padding: 1rem 1.5rem;
  border-radius: 10px;
  position: fixed;
  z-index: 10;
  bottom: 0;
  right: 0;
  display: flex;
  gap: 1rem;
  border-radius: 20px;
  transform: translateY(2vh);
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.active {
  opacity: 1;
  transition: all 0.3s ease-in-out;
  transform: translateY(0);
}
.spend > div {
  width: 100%;
  padding: 1rem 1.5rem;
  display: flex;
  gap: 1.5rem;
  justify-content: space-between;
  align-items: flex-start;
}
</style>
