<template>
  <section>
    <blockShimmer
      height="300px"
      width="100%"
      style="--shimmer-color: #f5f5f5"
      v-if="isLoading"
    />
    <div class="popular-categories rounded-lg" v-else>
      <div class="d-flex justify-content-between align-items-center mb-4">
        <h6 class="header-text section" style="margin-bottom: 0 !important">
          Popular Categories
        </h6>
        <router-link class="primary-link" to="/categories">See all</router-link>
      </div>
      <div class="containr px-1">
        <SlickCarousel v-bind="settings">
          <CategoryCard
            v-for="item in data.slice(0, 6)"
            :category="item"
            :key="item.id"
          />
        </SlickCarousel>
      </div>
    </div>
  </section>
</template>

<script>
import CategoryCard from "@/components/cards/ProductCategoryCard.vue";
import axios from "axios";

import { useQuery } from "@tanstack/vue-query";
import { blockShimmer } from "vue-shimmer";

const categories = async () => {
  let res = await axios.get("products/categories");
  return res.data.data;
};

export default {
  components: { CategoryCard, blockShimmer },
  data() {
    return {
      isMobileView: window.innerWidth < 500,
      settings: {
        autoplay: true,
        focusOnSelect: true,
        infinite: true,
        speed: 800,
        slidesToShow: 6,
        slidesToScroll: 1,
        touchThreshold: 5,
        centerMode: false,
        centerPadding: "3px",
        lazyLoad: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              variableWidth: true,
              infinite: true,
              autoplay: false,
            },
          },
        ],
      },
    };
  },
  setup() {
    const { data, isLoading, refetch } = useQuery({
      queryKey: ["categoriesListing"],
      queryFn: categories,
      retry: 1,
      keepPreviousData: true,
      staleTime: Infinity,
    });

    return { data, isLoading, refetch };
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobileView = window.innerWidth < 500;
    },
  },
};
</script>

<style scoped>
.categories-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

@media screen and (max-width: 500px) {
  .categories-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
}
</style>
