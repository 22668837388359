<template>
  <section class="popup">
    <a href="/" style="display: none" ref="dash"></a>
    <div class="popup__logout">
      <div class="popup__header">
        <h6>Log Out</h6>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          @click="$store.state.logout = false"
        >
          <path
            d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
            fill="black"
          />
        </svg>
      </div>
      <div class="popup__content mt-4">
        <h5>Do you want to logout</h5>
        <div class="popup__btn mt-4">
          <button style="background-color: red" @click="logout">Yes</button>
          <button @click="$store.state.logout = false">No</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from "vue";
import store from "@/store";
const dash = ref(null);
const logout = () => {
  window.localStorage.removeItem("token");
  window.sessionStorage.removeItem("user");

  dash.value.click();
  store.state.logout = false;

  store.commit("resetCart");
};
</script>

<style scoped>
.popup {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10000000;
}
.popup__logout {
  width: Min(90%, 444px);
  height: auto;
  /* min-height: 300px; */
  padding: 2rem 2rem !important;
  border: 0.5px;
  background-color: #ffffff;
  padding: 1rem;
  position: absolute;
  z-index: 15;
  border-radius: 1rem;
}
.popup__header {
  display: flex;
  justify-content: space-between;
}
.popup__header h6 {
  font-size: Max(1.5rem, 16px);
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0em;
  color: #002e22;
}
.popup__header svg {
  cursor: pointer;
}
.popup__btn {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.popup__btn button {
  width: 100%;
  height: 32px;
  border-radius: 4px;
  /* padding: 1.5rem 2rem; */
  background-color: #dddddd;
  text-align: center;
  font-size: Max(1rem, 16px);
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0em;
  color: white;
  text-align: center;
  outline: none;
  border: none;
}
</style>
