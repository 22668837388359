/* eslint-disable prettier/prettier */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VeeValidate from "vee-validate";
import PrimaryButton from "./components/buttons/PrimaryButton.vue";
import WalletButton from "./components/buttons/WalletButton";
import ValidationError from "./components/form/ValidationError.vue";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

// adding image directives
import "./functions/imageDirectives.js";

// import Toastify from "toastify-js";

// tanstack query
import { QueryClient, VUE_QUERY_CLIENT } from "@tanstack/vue-query";
// import { menuToggle } from "./functions/toggleMenu";

// toastify for notifications alert
import "toastify-js/src/toastify.css";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import VueIntersect from "vue-intersect-directive";

registerDirectives(Vue);

Vue.use(VueIntersect);

Vue.use(ElementUI);

Vue.component("vue-dropzone", vue2Dropzone);

Vue.use(VeeValidate);

Vue.component("PrimaryButton", PrimaryButton);

Vue.component("WalletButton", WalletButton);

Vue.component("ValidationError", ValidationError);

Vue.component("v-select", vSelect);

// Vue.use(menuToggle);

import { Icon } from "@iconify/vue2";
Vue.component("i-icon", Icon);

// Star Rating
import StarRating from "vue-star-rating";
Vue.component("StarRating", StarRating);

// Slick Carousel
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
Vue.component("SlickCarousel", VueSlickCarousel);

import "@/assets/css/main.css";
import "./registerServiceWorker";
import { registerDirectives } from "./directives/register";

Vue.config.productionTip = false;
Vue.config.silent = true;

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

const queryClient = new QueryClient();

new Vue({
  router,
  store,
  provide: {
    [VUE_QUERY_CLIENT]: queryClient,
  },
  render: (h) => h(App),
}).$mount("#app");
