const menu = [
  {
    id: 11,
    title: "Categories",
    url: null,
    hasChildren: true,
    icon: "ph:wallet",
    parent: "categories",
    children: [
      {
        id: 1,
        name: "Deals",
        title: "Deals",
        parent: "categories",
        url: "/",
      },
      {
        id: 2,
        name: "Health & Beauty",
        title: "Health & Beauty",
        parent: "categories",
        url: "/",
      },
      {
        id: 3,
        name: "Phones & Tablets",
        title: "Phones & Tablets",
        parent: "categories",
        url: "/",
      },
      {
        id: 4,
        name: "Electronics",
        title: "Electronics",
        parent: "categories",
        url: "/",
      },
      {
        id: 5,
        name: "Fashion",
        title: "Fashion",
        parent: "categories",
        url: "/",
      },
      {
        id: 6,
        name: "Home & Office",
        title: "Home & Office",
        parent: "categories",
        url: "/",
      },
    ],
  },
  {
    id: 1,
    title: "Account",
    parent: "account",
    url: "",
    icon: "teenyicons:user-outline",
    hasArrow: true,
    hasNotification: false,
  },
  {
    id: 2,
    title: "Orders & Tracking",
    parent: "orders-and-tracking",
    url: "/orders-and-tracking",
    icon: "ic:sharp-inventory",
    hasArrow: true,
    hasNotification: false,
  },
  // {
  //   id: 3,
  //   title: "Chat",
  //   parent: "inbox",
  //   url: "/inbox",
  //   icon: "ph:envelope-light",
  //   hasArrow: true,
  //   hasNotification: true,
  // },
  {
    id: 4,
    title: "Reviews and Ratings",
    parent: "reviews-and-ratings",
    url: "/reviews-and-ratings",
    icon: "material-symbols:reviews-outline",
    hasArrow: true,
    hasNotification: false,
  },
  {
    id: 5,
    title: "Saved Items",
    parent: "saved-items",
    url: "/saved-items",
    icon: "icon-park-outline:like",
    hasArrow: true,
    hasNotification: false,
  },
  {
    id: 6,
    title: "Notifications",
    parent: "notifications",
    url: "/notifications",
    icon: "ic:baseline-notifications-none",
    hasArrow: true,
    hasNotification: true,
  },
  {
    id: 7,
    title: "Wallet",
    url: null,
    hasChildren: true,
    icon: "ph:wallet",
    parent: "wallet",
    children: [
      {
        id: 1,
        title: "Wallet",
        parent: "Wallet",
        name: "emart-wallet",
        url: "/account/wallet/emart-wallet",
      },
      {
        id: 2,
        title: "Spend and save Wallet",
        parent: "Spend and save Wallet",
        name: "spend-and-save",
        url: "/account/wallet/spend-and-save",
      },
      {
        id: 3,
        title: "Smart Coin Wallet",
        parent: "Smart Coin Wallet",
        name: "smart-coin",
        url: "/account/wallet/smart-coin",
      },
    ],
  },
  {
    id: 8,
    title: "Newsletter Preference",
    parent: "newsletter-preference",
    url: "/newsletter-preference",
    icon: "quill:inbox-newsletter",
    hasArrow: true,
    hasNotification: false,
  },
  {
    id: 9,
    title: "Address Book",
    parent: "address-book",
    url: "/address-book",
    icon: "ion:location-outline",
    hasArrow: true,
    hasNotification: false,
  },
  {
    id: 10,
    title: "Logout",
    parent: "logout",
    url: "/logout",
    icon: "material-symbols:logout-rounded",
    hasArrow: false,
    hasNotification: false,
  },
];

export default menu;
