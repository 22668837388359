import store from "@/store";
import router from "@/router";

import { computed } from "vue";
import { ADD_PRODUCT_TO_CART } from "@/modules/cart/store/actions";

function useAddToCart(item, refetch = async () => {}) {
  const isProductInCart = computed(() => {
    const items = store.state.cartModule.items;
    return Object.keys(items).some((id) => id.includes(item.uuid));
  });

  const isAddingToCart = computed(() => {
    return store.state.cartModule.addingItemsMap[item.uuid] !== undefined;
  });

  const addProductToCart = async () => {
    if (isProductInCart.value) {
      router.push("/cart");
      return;
    }

    await store.dispatch(ADD_PRODUCT_TO_CART, { product: item });
    await refetch();
  };

  return {
    isProductInCart,
    isAddingToCart,
    addProductToCart,
  };
}

export default useAddToCart;
