<template>
  <section class="popup">
    <div class="popup__item">
      <div class="popup__header">
        <h6>Please select a variation</h6>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          @click.stop="store.commit(SELECT_PRODUCT_VARIANT, null)"
        >
          <path
            d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
            fill="black"
          />
        </svg>
      </div>
      <div class="popup__content">
        <div class="variation">
          <ProductCartVariant
            v-for="variant of variants"
            :key="JSON.stringify(variant)"
            :size="variant.size"
            :max="product.quantity"
            :onChange="updateVariant"
            :quantity="variant.quantity"
            :colors="props.product.colors"
            :actualPrice="product.actual_price"
            :sellingPrice="product.selling_price"
            :selectedColor="variant.color"
          />
        </div>
      </div>
      <div class="popup_actions" v-if="isProductInCart">
        <button
          class="btn--primary buy--btn"
          @click="store.commit(SELECT_PRODUCT_VARIANT, null)"
        >
          Continue Shopping
        </button>
        <button class="btn--secondary buy--btn" @click="goToCart">
          Go to Cart
        </button>
      </div>
      <div class="popup_actions" v-else>
        <button
          class="btn--secondary buy--btn"
          @click="addToCartHandler"
          :disabled="isAddingProductToCart"
        >
          Add to Cart
          <div v-if="isAddingProductToCart">
            <Spinner class="ml-3" />
          </div>
        </button>
      </div>
    </div>
  </section>
</template>

<script setup>
import store from "@/store";
import router from "@/router";
import Spinner from "@/layouts/spinner.vue";
import ProductCartVariant from "@/components/cards/productCartVariant.vue";

import { computed, defineProps, onMounted, ref, watch } from "vue";
import { SELECT_PRODUCT_VARIANT } from "@/modules/cart/store";
import { ADD_PRODUCT_TO_CART } from "@/modules/cart/store/actions";
import { upsertCartItemVariant } from "@/functions/utils";

const props = defineProps(["product"]);

const variants = ref([]);
const cartItemId = ref(null);
const itemsInCart = ref([]);

const updateItemsInCart = () => {
  const products = [];
  const items = store.state.cartModule.items;

  for (const id in items) {
    if (id.includes(props.product.uuid)) {
      products.push(items[id]);

      if (!cartItemId.value) cartItemId.value = items[id].uuid;
    }
  }

  itemsInCart.value = products;

  if (products.length) {
    variants.value = variants.value.map((variant) => {
      const variantInCart = products.find(
        (product) => product.sizes === variant.size
      );

      if (variantInCart) {
        variant.color = variantInCart.colors;
        variant.quantity = variantInCart.quantity;
      }

      return variant;
    });
  }
};

const isProductInCart = computed(() => itemsInCart.value.length > 0);
const isAddingProductToCart = computed(
  () => store.state.cartModule.addingItemsMap[props.product.uuid] !== undefined
);

const updateVariant = (updatedVariant, quantity) => {
  variants.value = variants.value.map((variant) => {
    if (updatedVariant.size === variant.size) {
      variant.quantity = quantity;
      variant.color = updatedVariant.color;
    }

    return variant;
  });

  if (isProductInCart.value) {
    addToCartHandler();
  }
};

const addToCartHandler = async () => {
  const completeVariants = variants.value
    .filter((variant) => variant.quantity > 0)
    .map((variant) => {
      if (props.product.colors) {
        variant.colors = variant.color;
      }

      if (props.product.sizes) {
        variant.sizes = variant.size;
      }

      return variant;
    });

  if (!isProductInCart.value) {
    store.dispatch(ADD_PRODUCT_TO_CART, {
      product: props.product,
      variants: completeVariants,
    });

    return;
  }

  await upsertCartItemVariant(props.product.uuid, ...completeVariants);

  // if (status) {
  //   this.quantitySnapshot = this.quantity;
  // } else {
  //   this.quantity = this.quantitySnapshot;
  // }
};

const goToCart = () => {
  store.commit(SELECT_PRODUCT_VARIANT, null);
  router.push("/cart");
};

watch(() => store.state.cartModule.items, updateItemsInCart, {
  deep: true,
  immediate: true,
});

onMounted(() => {
  let mainVariant = props.product.sizes;

  if (!mainVariant && props.product?.colors?.length > 1) {
    mainVariant = props.product?.colors.map((_, index) => `Item ${index + 1}`);
  }

  variants.value = mainVariant.map((size) => {
    let quantity = 0;
    let color = props.product.colors[0];

    const variantInCart = itemsInCart.value.find(
      (product) => product.sizes === size
    );

    if (size === props.product.variant.sizes) {
      quantity = props.product.variant.quantity;
    }

    if (props.product.variant.colors) {
      color = props.product.variant.colors;
    }

    if (variantInCart) {
      color = variantInCart.colors;
      quantity = variantInCart.quantity;

      return {
        size,
        color,
        quantity,
        uuid: variantInCart.uuid,
        id: variantInCart.id,
      };
    }

    // TODO: REFACTOR TO CART ITEM VARIANT GENERATOR
    let id = cartItemId.value;

    id = `${id}+sizes:${size}`;

    if (color) {
      id = `${id}+colors:${color}`;
    }

    return {
      id,
      size,
      color,
      quantity,
      uuid: cartItemId.value,
    };
  });
});
</script>

<style scoped>
button[disabled] {
  filter: opacity(0.5);
}

.popup {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100dvh;
  top: 0;
  left: 0;
  z-index: 10000000;
}

.popup__item {
  width: min(95%, 444px);
  min-height: 426px;
  max-height: 95%;
  border: 0.5px;
  background-color: #ffffff;
  padding: 2rem 2rem !important;
  display: flex;
  flex-direction: column;
}

.popup__header {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.popup__header h6 {
  font-size: Max(1.25rem, 16px);
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0em;
  color: #002e22;
}

.popup__header svg {
  cursor: pointer;
}

.popup__content {
  padding: 1.5rem 0;
  margin: 0.5rem 0;
  flex-grow: 0;
  flex-shrink: 1;
  overflow-y: scroll;
}

.popup__content::-webkit-scrollbar {
  width: 3px;
}

.popup_actions {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 0.5rem;
  flex: 1;
}

.popup_actions button {
  margin-top: 0rem;
}

button {
  padding: 1rem 2rem;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0em;
  color: white;
  text-align: center;
  outline: none;
  margin-top: 1rem;
  border: none;
}
.dropdown {
  position: relative;
}
.dropdown input {
  position: relative;
}
.dropdown__bottom {
  position: absolute;
  background-color: #fff;
  max-height: 150px;
  width: 100%;
  overflow: auto;
  text-align: left;
  padding: 0.5rem;
  z-index: 3;
}
.dropdown__bottom__item {
  display: flex;
  flex-direction: column;
}
.dropdown__bottom span {
  border-bottom: 1px solid #f9f9f9;
  margin: 0.35rem;
}

.text-error {
  padding: 3px 0;
  display: inline-block;
  font-size: 0.9rem;
}

@media only screen and (max-width: 600px) {
  .popup__item {
    padding: 1.5rem !important;
  }
}
</style>
