<template>
  <section class="mobnav" :class="{ mobnav__active: $store.state.menuState }">
    <div class="mobnav__logo">
      <img src="@/assets/icons/cancel.svg" alt="" @click="menu" />
      <img src="@/assets/logo.png" loading="lazy" width="80" alt="" />
    </div>
    <div
      class="mobnav__items"
      v-for="item in menuItem.filter((item) => !item.hide)"
      :key="item.id"
      @click="toggleMenuDropdown(item)"
    >
      <div class="mobnav__items__top">
        <h4>{{ item.title }}</h4>
        <img
          src="@/assets/icons/arr-right.svg"
          loading="lazy"
          alt=""
          :class="{ imgtilt: item.id === showActive }"
        />
      </div>
      <div
        class="dropdown_wrapper"
        :class="{ expanded: item.id === showActive }"
        ref="menuDropDown"
      >
        <div class="mobnav__items__bottom">
          <div class="spacer"></div>
          <span v-for="sub in item.subChild" :key="sub.item">
            <!-- <div v-if="sub.route">
            <div v-html="sub.icon" v-if="sub.icon"></div>
            <p v-if="sub.item" @click="menu()">
              {{ sub.item }}
            </p>
            <p v-if="sub.name">{{ sub.name }}</p>
          </div> -->
            <div class="flex-item">
              <div class="cart__count">
                <!-- <div v-html="sub.icon" v-if="sub.icon" class="action-icon"></div> -->
                <i-icon
                  width="15"
                  height="15"
                  class="user-icon action-icon"
                  v-if="sub.icon"
                  :icon="sub.icon"
                />
                <span
                  v-if="cartItemsCount > 0"
                  v-show="sub.item === `Cart`"
                  class="count"
                  >{{ cartItemsCount }}</span
                >
              </div>
              <p v-if="sub.item && !sub.url" @click="menu()">
                <a
                  :href="sub.route"
                  v-if="sub.route"
                  style="color: inherit"
                  target="__blank"
                  >{{ sub.item }}</a
                >
              </p>
              <p v-if="sub.url" @click="$router.push(`${sub.url}`), menu()">
                {{ sub.item }}
              </p>
              <p v-if="sub.help" @click="goto(sub.helpLink)">
                {{ sub.help }}
              </p>
              <p
                v-if="sub.name"
                @click="$router.push(`/category/${sub.uuid}`), menu()"
              >
                {{ sub.name }}
              </p>
            </div>
          </span>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import axios from "axios";
import store from "@/store";

import { computed, ref, onMounted } from "vue";
import { useQuery } from "@tanstack/vue-query";
import { CART_ITEMS_COUNT } from "@/modules/cart/store";

const cartItemsCount = computed(() => store.getters[CART_ITEMS_COUNT]);

const showActive = ref(null);
const auth = ref(null);
const menuItem = ref([
  {
    id: 1,
    title: "NEED HELP?",
    extend: false,
    subChild: [
      {
        help: "Place an Order",
        helpLink: "https://support.sellersmart.ng/",
      },
      {
        help: "Track an Order",
        helpLink: "https://support.sellersmart.ng/",
      },
      {
        help: "Cancel an Order",
        helpLink: "https://support.sellersmart.ng/",
      },
      {
        help: "Return goods",
        helpLink: "https://support.sellersmart.ng/",
      },
      {
        help: "Payment Options",
        helpLink: "https://support.sellersmart.ng/",
      },
      {
        help: "Help Center ",
        helpLink: "https://support.sellersmart.ng/",
      },
      {
        help: "About Us",
        helpLink: "https://support.sellersmart.ng/",
      },
    ],
  },
  {
    id: 2,
    title: "MY ACCOUNT",
    extend: false,
    subChild: [
      {
        icon: "teenyicons:user-outline",
        item: "My Account",
        url: "/account",
      },
      {
        icon: "ic:sharp-inventory",
        item: "Orders & Tracking ",
        url: "/account/orders-and-tracking",
      },
      {
        icon: "material-symbols:reviews-outline",
        item: "Reviews and Ratings",
        url: "/account/reviews-and-ratings",
      },
      {
        icon: "ic:shopping-cart",
        item: "Cart",
        url: "/cart",
      },
      {
        icon: "icon-park-outline:like",
        item: "Saved Items",
        url: "/account/saved-items",
      },
      {
        icon: "ic:baseline-notifications-none",
        item: "Notifications",
        url: "/account/notifications",
      },
      {
        icon: "ph:wallet",
        item: "My Wallet",
        url: "/account/wallet/emart-wallet",
      },
      {
        icon: "ph:wallet",
        item: "Spend&Save Wallet",
        url: "/account/wallet/spend-and-save",
      },
      {
        icon: "ph:wallet",
        item: "SmartCoin Wallet",
        url: "/account/wallet/smart-coin",
      },
      {
        icon: "quill:inbox-newsletter",
        item: "Newsletter",
        url: "account/newsletter-preference",
      },
      {
        icon: "ion:location-outline",
        item: "Address Book",
        url: "account/address-book",
      },
    ],
  },
  {
    id: 3,
    title: "CATEGORIES",
    extend: true,
    subChild: [],
  },
  {
    id: 4,
    title: "Earn on SellersMart",
    extend: false,
    subChild: [
      {
        item: "Become a Vendor",
        route: "https://vendors.sellersmart.ng",
      },
      {
        item: "Become an Affiliate",
        route: "https://affiliates.sellersmart.ng/",
      },
    ],
  },
  {
    id: 5,
    title: "LOGIN",
    extend: false,
    hide: auth.value,
  },
  {
    id: 5,
    title: "LOGOUT",
    extend: false,
    hide: !auth.value,
  },
]);

const menu = () => {
  store.dispatch("handleMenu");
};

const toggleMenuDropdown = (value) => {
  if (value.title === "LOGOUT") {
    store.state.logout = true;
  } else if (value.title === "LOGIN") {
    store.state.AccessState = 1;
  } else if (showActive.value === value.id) {
    showActive.value = null;
  } else {
    showActive.value = value.id;
  }
};

const goto = (link) => {
  window.open(link);
  menu();
};

const category = async () => {
  let res = await axios.get("/products/categories");
  return res.data.data.slice(0, 6);
};

const { data, refetch } = useQuery({
  queryKey: ["menucateg"],
  queryFn: category,
  retry: 1,
  keepPreviousData: true,
  staleTime: Infinity,
});

onMounted(async () => {
  await refetch();
  menuItem.value[2].subChild = data.value;
  if (window.sessionStorage.getItem("user") === "authenticated") {
    auth.value = false;
  } else {
    auth.value = true;
  }
});
</script>

<style scoped>
@media screen and (max-width: 990px) {
  .cart__count {
    position: relative;
  }
  .cart__count .action-icon {
    position: relative;
  }
  .count {
    background-color: #004e3a;
    text-align: center;
    padding: 0.1rem;
    position: absolute;
    top: -30%;
    right: -30%;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    font-size: 12px;
    font-family: THICCCBOI;
    font-weight: 300;
    color: #ffffff;
    display: flex;
    justify-content: center;
  }
  .mobnav {
    overflow: auto;
    max-width: 100vw;

    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    transform: translateX(100%);
    top: 0;
    position: fixed;
    z-index: 100001;
    transition: all 0.5s ease-in-out;
    background-color: white;
    padding: 2rem 0;
  }
  .mobnav__active {
    transform: unset;
  }

  .hidden {
    display: block;
  }

  .mobnav__logo {
    display: flex;
    gap: 1rem;
    margin-left: 1rem;
    margin-top: 0.8rem;
    align-items: center;
    margin-bottom: 1.5rem;
  }

  .mobnav__items {
    border-top: 2px solid #e6f3f0;
    padding: 1rem 1.5rem;
  }

  .mobnav__items__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .mobnav__items__top h4 {
    font-family: THICCCBOI;
    font-size: 16px;
    font-weight: 500;
    line-height: 175%;
    text-align: left;
    color: #717171;
  }

  .mobnav__items__bottom span {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
  }

  .flex-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 0.5rem 0;
    cursor: pointer;
    width: 100%;
  }

  .mobnav__items__bottom {
    overflow: hidden;
  }

  .mobnav__items__bottom .spacer {
    height: 0.5rem;
  }

  .mobnav__items__bottom span p {
    font-family: THICCCBOI;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: black;
    padding: 1rem !important;
    flex: 1;
  }

  .mobnav__items__bottom span p:active {
    background-color: #cccccc50;
  }

  .dropdown_wrapper {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 300ms ease;
  }

  .dropdown_wrapper.expanded {
    grid-template-rows: 1fr;
  }

  .logout {
    font-family: THICCCBOI;
    font-size: 12px;
    font-weight: 400;
    line-height: 175%;
    text-align: left;
    color: #717171;
    margin: 1.5rem !important;
    cursor: pointer;
  }
  .imgtilt {
    transform: rotate(90deg);
    transition: all 0.15s ease-in-out;
  }
}
</style>
