<template>
  <Fragment>
    <BlockLoader v-if="isLoading" />
    <div class="new-arrivals" v-else-if="data.new_arrivals?.length">
      <div class="d-flex justify-content-between align-items-center mb-4">
        <h6 class="header-text section" style="margin-bottom: 0 !important">
          New Arrivals
        </h6>
        <router-link class="primary-link" to="/products?filter=new_arrivals"
          >See all</router-link
        >
      </div>
      <div class="container">
        <SlickCarousel v-bind="settings">
          <ProductCardMini
            v-for="item in data.new_arrivals"
            :item="item"
            :key="item.id"
            class="mr-3"
          />
        </SlickCarousel>
      </div>
    </div>
  </Fragment>
</template>

<script>
import axios from "axios";
import ProductCardMini from "@/components/cards/ProductCardMini.vue";
import BlockLoader from "@/components/static/BlockLoader.vue";

import { Fragment } from "vue-frag";
import { useQuery } from "@tanstack/vue-query";

const getMainStoreData = async () => {
  const mainStoreResponse = await axios.get("main-store");

  if (mainStoreResponse.data?.data?.flash_pick_of_the_day?.length) {
    mainStoreResponse.data.data.flash_pick_of_the_day =
      mainStoreResponse.data.data.flash_pick_of_the_day.slice(0, 3);
  }

  return mainStoreResponse.data.data;
};

export default {
  components: { ProductCardMini, Fragment, BlockLoader },
  data() {
    return {
      settings: {
        // dots: true,
        autoplay: true,
        focusOnSelect: true,
        infinite: true,
        speed: 800,
        slidesToShow: 5,
        slidesToScroll: 1,
        touchThreshold: 5,
        centerMode: false,
        centerPadding: "8px",
        lazyLoad: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  setup() {
    const arrivalsQuery = useQuery({
      queryKey: ["arrivals"],
      queryFn: getMainStoreData,
      retry: 1,
      keepPreviousData: true,
      staleTime: Infinity,
    });

    return arrivalsQuery;
  },
};
</script>

<style scoped>
.arrivals-container {
  height: auto;
}

@media screen and (max-width: 900px) {
  .new-arrivals {
    width: 100%;
  }

  .arrivals-container {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    height: auto;
  }
}
</style>
