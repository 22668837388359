const Home = () => import("../pages/indexPage.vue");
const HomeView = () => import("../views/accounts/myAccount.vue");
const OrdersAndTracking = () =>
  import("../views/accounts/ordersAndTracking.vue");
const Inbox = () => import("../views/accounts/messages/views/indexView");
const ReviewsAndRatings = () =>
  import("../views/accounts/reviewsAndratings.vue");
const RateProduct = () => import("../views/accounts/RateProduct.vue");
const AddressBook = () => import("../views/accounts/addressBook.vue");
const EmartWallet = () => import("../views/accounts/emartWallet.vue");
const SpendAndSave = () => import("../views/accounts/spendAndSave.vue");
const SmartCoin = () => import("../views/accounts/smartcoinwallet.vue");
const OrderDetails = () => import("../views/accounts/orderDetails.vue");
const PackageHistory = () => import("../views/accounts/packageHistory.vue");
const CancelOrder = () => import("../views/accounts/cancelOrder.vue");
const OrderCancelled = () => import("../views/accounts/orderCancelled");
const SavedItems = () => import("../views/accounts/savedItems");
const NewsletterPreference = () =>
  import("../views/accounts/newsletterPreference");

const Cart = () => import("../pages/cartPage.vue");

const CartView = () => import("../views/cart/cartView.vue");
const CheckOut = () => import("../views/cart/checkoutView.vue");

const OrderComplete = () => import("../pages/orderComplete.vue");
const Notification = () => import("../views/accounts/notificationsPage.vue");

const routes = [
  {
    path: "/account",
    component: Home,
    meta: {
      home: "account",
      layout: "homeLayout",
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        name: "mccount",
        component: HomeView,
        meta: {
          home: "account",
          layout: "homeLayout",
          parent: "account",
          name: "My Account",
          subTitle: false,
          requiresAuth: true,
        },
      },

      {
        path: "orders-and-tracking",
        name: "orders-and-tracking",
        component: OrdersAndTracking,
        meta: {
          home: "account",
          layout: "homeLayout",
          parent: "orders-and-tracking",
          name: "Orders and Tracking",
          subTitle: false,
          requiresAuth: true,
        },
      },

      {
        path: "notifications",
        name: "Notifications",
        component: Notification,
        meta: {
          home: "account",
          layout: "homeLayout",
          parent: "notifications",
          name: "Notifications",
          subTitle: false,
          requiresAuth: true,
        },
      },

      {
        path: "saved-items",
        name: "saved-items",
        component: SavedItems,
        meta: {
          home: "account",
          layout: "homeLayout",
          parent: "saved-items",
          name: "Saved Items",
          subTitle: false,
          requiresAuth: true,
        },
      },

      {
        path: "inbox",
        name: "inbox",
        component: Inbox,
        meta: {
          home: "account",
          layout: "homeLayout",
          parent: "inbox",
          name: "Inbox Messages",
          subTitle: false,
          requiresAuth: true,
        },
      },

      {
        path: "reviews-and-ratings",
        name: "reviews-and-ratings",
        component: ReviewsAndRatings,
        meta: {
          home: "account",
          layout: "homeLayout",
          parent: "reviews-and-ratings",
          name: "Reviews and Ratings",
          subTitle: false,
          requiresAuth: true,
        },
      },

      {
        path: "reviews-and-ratings/rate-product",
        name: "rate-product",
        component: RateProduct,
        meta: {
          home: "account",
          layout: "homeLayout",
          parent: "reviews-and-ratings",
          name: "Reviews and Rate this product",
          sutTitle: "Rate Product",
          subTitle: false,
          detailsPage: true,
          requiresAuth: true,
        },
      },

      {
        path: "newsletter-preference",
        name: "newsletter-preference",
        component: NewsletterPreference,
        meta: {
          home: "account",
          layout: "homeLayout",
          parent: "newsletter-preference",
          name: "Newsletter Preference",
          subTitle: false,
          requiresAuth: true,
        },
      },

      {
        path: "address-book",
        name: "address-book",
        component: AddressBook,
        meta: {
          home: "account",
          layout: "homeLayout",
          parent: "address-book",
          name: "Address Book",
          subTitle: false,
          requiresAuth: true,
        },
      },

      {
        path: "wallet/emart-wallet",
        name: "emart-wallet",
        component: EmartWallet,
        meta: {
          home: "account",
          layout: "homeLayout",
          parent: "wallet",
          name: "Sellersmart Wallet",
          subName: "emart-wallet",
          subTitle: "Sellersmart Wallet",
          requiresAuth: true,
        },
      },

      {
        path: "wallet/spend-and-save",
        name: "spend-and-save",
        component: SpendAndSave,
        meta: {
          home: "account",
          layout: "homeLayout",
          parent: "wallet",
          name: "Spend and save Wallet",
          subName: "spend-and-save",
          subTitle: "Spend and Save Wallet",
          requiresAuth: true,
        },
      },

      {
        path: "wallet/smart-coin",
        name: "smart-coin",
        component: SmartCoin,
        meta: {
          home: "account",
          layout: "homeLayout",
          parent: "wallet",
          name: "Smart Coin Wallet",
          subName: "smart-coin",
          subTitle: "Smart Coin Wallet",
          requiresAuth: true,
        },
      },

      {
        path: "order/:reference",
        name: "order-details",
        component: OrderDetails,
        props: true,
        meta: {
          home: "account",
          layout: "homeLayout",
          parent: "orders-and-tracking",
          name: "Order Details",
          detailsPage: true,
          requiresAuth: true,
        },
      },

      {
        path: "order/id/package-history",
        name: "package-history",
        component: PackageHistory,
        meta: {
          home: "account",
          layout: "homeLayout",
          parent: "orders-and-tracking",
          name: "Package History",
          detailsPage: true,
          requiresAuth: true,
        },
      },

      {
        path: "cancel-order",
        name: "cancel-order",
        component: CancelOrder,
        meta: {
          home: "account",
          layout: "homeLayout",
          parent: "orders-and-tracking",
          name: "Cancel Order",
          detailsPage: true,
          requiresAuth: true,
        },
      },

      {
        path: "order-cancelled",
        name: "order-cancelled",
        component: OrderCancelled,
        meta: {
          home: "account",
          layout: "homeLayout",
          parent: "orders-and-tracking",
          name: "Order Cancelled",
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "",
    component: Cart,
    meta: {
      home: "cart",
      layout: "homeLayout",
      // requiresAuth: true,
    },
    children: [
      {
        path: "/cart",
        component: CartView,
        meta: {
          name: "cart",
          home: "cart",
          layout: "homeLayout",
          // requiresAuth: true,
        },
      },
      {
        path: "/checkout",
        component: CheckOut,
        meta: {
          name: "checkout",
          layout: "homeLayout",
          home: "cart",
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/order-complete",
    component: OrderComplete,
    meta: {
      name: "order-complete",
      layout: "homeLayout",
      home: "cart",
      requiresAuth: true,
    },
  },
];
// meta: {requiresAuth: true}

export default routes;
