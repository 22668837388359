<template>
  <section class="popup">
    <section class="popup__one" v-if="steps === 1">
      <div class="mb-3">
        <h2>Create an account</h2>
        <p>
          Already have an account?
          <span @click="$store.state.AccessState = 1">Sign in</span>
        </p>
      </div>
      <img
        src="@/assets/img/createaccount.svg"
        loading="lazy"
        alt=""
        height="265"
        width="308"
        class="mb-3"
      />
      <button class="mail" @click="steps = steps + 1">
        Continue with email
      </button>
      <button class="google">Continue with Google</button>
    </section>

    <div class="popup__item" v-if="steps === 2">
      <div class="popup__header">
        <h6>Sign Up</h6>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          @click="$store.state.AccessState = null"
        >
          <path
            d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
            fill="black"
          />
        </svg>
      </div>
      <div class="popup__content">
        <form action="" @submit.prevent="handleSubmit">
          <div>
            <label for="">First Name</label>
            <input
              type="text"
              placeholder="Enter First name"
              v-model="userInfo.firstname"
              name="first name"
              v-validate="'required|alpha_spaces|max:100'"
              maxlength="100"
              :class="{ 'border-danger': errors.has('first name') }"
            />
            <ValidationError
              v-show="errors.has('first name')"
              :text="errors.first('first name')"
            />
          </div>

          <div>
            <label for="">Last Name</label>
            <input
              type="text"
              placeholder="Enter Last Name"
              v-model="userInfo.lastname"
              name="last name"
              v-validate="'required|alpha_spaces|max:100'"
              maxlength="100"
              :class="{ 'border-danger': errors.has('last name') }"
            />
            <ValidationError
              v-show="errors.has('last name')"
              :text="errors.first('last name')"
            />
          </div>

          <div>
            <label for="">Enter Email address</label>
            <input
              type="email"
              placeholder="Enter Email address"
              v-model="userInfo.email"
              name="email"
              v-validate="'required|email|max:255'"
              maxlength="255"
              :class="{ 'border-danger': errors.has('email') }"
            />
            <ValidationError
              v-show="errors.has('email')"
              :text="errors.first('email')"
            />
          </div>

          <div>
            <label for="">Password</label>
            <div class="pword_input">
              <input
                :type="visibled ? 'text' : 'password'"
                placeholder="Enter Password"
                v-model="userInfo.password"
                name="password"
                v-validate="'required|min:5|max:30'"
                maxlength="100"
                :class="{ 'border-danger': errors.has('password') }"
              />
              <span v-if="visibled" @click="toggleVisiblity" class="corner">
                <i class="fa-regular fa-eye"></i>
              </span>
              <span v-else @click="toggleVisiblity" class="corner"
                ><i class="fa-regular fa-eye-slash"></i
              ></span>
            </div>
            <ValidationError
              v-show="errors.has('password')"
              :text="errors.first('password')"
            />
          </div>

          <PrimaryButton
            :loading="loading"
            :disabled="loading"
            :class="{ Loading: loading }"
          >
            Create an Account
          </PrimaryButton>
        </form>
        <p class="terms small">
          By signing up you accept our
          <a href="/terms-of-service" target="__blank">terms and condition</a>
          &
          <a
            href="https://company.sellersmart.ng/privacy-policy"
            target="__blank"
            >privacy policy</a
          >.
        </p>
        <p class="new__account">
          Already have an account
          <span @click="$store.state.AccessState = 1">Sign In</span>
        </p>
      </div>
    </div>

    <section class="popup__one" v-if="steps === 3">
      <h2>Verify your email</h2>
      <p>
        We have sent an email verification link to {{ maskStr(userInfo.email) }}
      </p>
      <img
        src="@/assets/img/email.svg"
        loading="lazy"
        class="emailverify"
        alt=""
        height="265email"
        width="308"
      />
      <button @click="$store.state.AccessState = 1">Continue</button>
      <p>Didn’t receive the link?</p>
      <p class="resend">Resend link ({{ seconds }}s)</p>
    </section>
  </section>
</template>

<script>
import axios from "axios";
import { fail, success } from "@/functions/toast";
import Recaptcha from "@/functions/captcha.mixin";
export default {
  mixins: [Recaptcha],
  data() {
    return {
      steps: 2,
      loading: false,
      visibled: false,
      seconds: 60,
      userInfo: {
        email: "",
        firstname: "",
        lastname: "",
        phone_number: "",
        password: "",
        password_confirmation: "",
        recaptcha: "",
      },
    };
  },
  methods: {
    async handleSubmit() {
      // eslint-disable-next-line no-undef
      const captcha = await grecaptcha.enterprise.execute(
        process.env.VUE_APP_SITE_KEY,
        { action: "REGISTER_CUSTOMER" }
      );
      this.userInfo.recaptcha = captcha;
      await this.$validator.validateAll().then(async (result) => {
        if (result) {
          this.userInfo.password_confirmation = this.userInfo.password;
          this.loading = true;
          try {
            await axios.post("customer", this.userInfo);
            this.loading = false;
            this.steps = 3;
            this.counter();
            success("Account created");
          } catch (e) {
            fail(e.response.data.message);
            this.loading = false;
          }
        }
      });
    },
    counter() {
      const timer = setInterval(() => {
        this.seconds = this.seconds - 1;
        if (this.seconds === 0) {
          this.seconds = 60;
          this.handleSubmit();
          // clearInterval(timer);
          return { timer };
        }
      }, 1000);
    },
    maskStr(str) {
      return str.replace(/(?<=.{3}).(?=[^@]*?@)/g, "*");
    },
    toggleVisiblity() {
      this.visibled = !this.visibled;
    },
  },
  mounted() {
    if (this.steps === 3) {
      this.counter();
    }
  },
};
</script>

<style scoped>
.popup {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1000000;
}
button[disabled] {
  filter: opacity(0.5);
}
.pword_input {
  position: relative;
}
.pword_input input {
  position: relative;
}
.corner {
  position: absolute;
  right: 5%;
  top: 30%;
  cursor: pointer;
}
.popup__item,
.popup__one {
  width: Min(90%, 444px);
  min-height: 300px;
  max-height: 90vh;
  overflow: auto;
  padding: 2rem 2rem !important;
  border: 0.5px;
  background-color: #ffffff;
  padding: 1rem;
  position: absolute;
  z-index: 15;
  border-radius: 1rem;
  /* scrollbar-width: none; */
}
.popup__item::-webkit-scrollbar,
.popup__one::-webkit-scrollbar {
  display: none;
}
.popup__one {
  text-align: center;
}
.popup__one h2 {
  text-align: center;
  font-family: var(--primary-font-family);
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
}
.popup__one p {
  font-family: var(--primary-font-family);
  font-size: 14px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 0.5rem !important;
}
.popup__one h5 {
  font-family: var(--primary-font-family);
  font-size: 14px;
  letter-spacing: 0em;
  text-align: center;
  margin: 1rem 0 !important;
}
.resend {
  color: #006e52;
}
.popup__one p span {
  color: #006e52;
  cursor: pointer;
}
.popup__header {
  display: flex;
  justify-content: space-between;
}
.popup__header h6 {
  font-size: Max(1.25rem, 16px);
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0em;
  color: #002e22;
}
.popup__header svg {
  cursor: pointer;
}
.emailverify {
  margin: 1rem 0;
}
.remember {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}
.remember span {
  display: flex;
  align-items: center;
  gap: 0.35rem;
}
.remember span input {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.remember h6 {
  margin-bottom: unset;
  color: #006e52;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-decoration: underline;
  cursor: pointer;
}
label {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: #434343;
  margin-top: 1rem;
}
input {
  width: 100%;
  height: 48px;
  padding: 0.75rem;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  outline: none;
  font-size: Max(1rem, 16px);
  font-weight: 400;
  line-height: 175%;
  letter-spacing: 0em;
  color: rgba(69, 69, 69);
}
input::placeholder {
  font-size: Max(1rem, 16px);
  font-weight: 400;
  line-height: 175%;
  letter-spacing: 0em;
  color: rgba(69, 69, 69, 0.75);
}
button {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  padding: 1.5rem 2rem;
  background-color: #006e52;
  text-align: center;
  font-size: Max(1rem, 16px);
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0em;
  color: white;
  text-align: center;
  outline: none;
  margin-top: 1rem;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.google {
  background-color: transparent;
  border: 1px solid #006e52;
  color: #006e52;
  background-image: url("@/assets/icons/google.svg");
  background-repeat: no-repeat;
  background-position: 10% center;
  margin-bottom: 3rem;
}
.mail {
  background-image: url("@/assets/icons/email.svg");
  background-repeat: no-repeat;
  background-position: 10% center;
  margin-top: 2rem;
}
.new__account {
  font-size: 14px;
  /* font-weight: 500; */
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
}
.new__account span {
  color: #006e52;
  cursor: pointer;
}
.terms {
  width: Min(373px, 100%);
  text-align: center;
  margin: 1.5rem auto !important;
}
</style>
