<template>
  <section>
    <div v-if="isLoading">
      <sentencesShimmer
        height="400px"
        width="100%"
        style="--shimmer-color: #d7d5d1"
      />
      <sentencesShimmer
        height="400px"
        width="100%"
        style="--shimmer-color: #d7d5d1"
      />
    </div>
    <div class="for-you" v-else>
      <div class="d-flex justify-content-between align-items-center mb-4">
        <h6 class="header-text section" style="margin-bottom: 0 !important">
          For You
        </h6>
        <router-link class="primary-link" to="/products?filter=for_you"
          >See all</router-link
        >
      </div>
      <div class="container">
        <SlickCarousel v-bind="settings">
          <ProductCard
            v-for="item in deals"
            :item="item"
            :refetch="refetch"
            :key="item.id"
          />
        </SlickCarousel>
      </div>
    </div>
  </section>
</template>

<script>
// import SuggestedProductsCard from "@/components/cards/suggestedProductsCard.vue";
import ProductCard from "@/components/cards/ProductCard.vue";
// import { useQuery } from "@tanstack/vue-query";
// import axios from "axios";
import { sentencesShimmer } from "vue-shimmer";

// const topDeals = async () => {
//   let res = await axios.get("popular-stores-and-vendors");
//   return res.data.data.top_deals;
// };

export default {
  components: { ProductCard, sentencesShimmer },
  props: ["deals", "refetch", "isLoading"],
  data() {
    return {
      settings: {
        autoplay: true,
        // dots: true,
        focusOnSelect: true,
        infinite: true,
        speed: 800,
        slidesToShow: 5,
        slidesToScroll: 1,
        touchThreshold: 3,
        centerMode: false,
        centerPadding: "0px",
        lazyLoad: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  setup() {
    // const { data, isLoading, refetch } = useQuery({
    //   queryKey: ["foryou"],
    //   queryFn: topDeals,
    //   retry: 1,
    //   keepPreviousData: true,
    // });
    // return { data, isLoading, refetch };
  },
};
</script>

<style scoped>
::deep(.slick-track) {
  display: none;
}

@media only screen and (max-width: 600px) {
  .container {
    padding: 0.25rem !important;
  }
}
</style>
