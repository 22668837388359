import store from "@/store";
import { useIntersectionObserver } from "@vueuse/core";

const CampaignInsights = {
  inserted: function (el, binding) {
    const observer = useIntersectionObserver(el, ([{ isIntersecting }]) => {
      if (isIntersecting) {
        store.commit("addToCampaignInsightsBatch", {
          campaignId: binding.value,
          type: binding.arg || "views",
        });
        observer.stop();
      }
    });
  },
};

export default CampaignInsights;
