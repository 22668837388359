<template>
  <section>
    <div class="container py-4">
      <section class="bg-white p-2 p-md-4 mb-4 rounded-lg">
        <header class="header">
          <div class="hero-section d-flex" style="gap: 30px">
            <HeaderCarousel />
            <div class="flashpick">
              <SmartCampaign />
            </div>
          </div>
        </header>

        <section class="" style="margin-top: 30px">
          <div class="popular d-flex align-items-center" style="gap: 30px">
            <div class="w-100">
              <PopularCategories class="h-100" />
            </div>
          </div>
        </section>

        <section style="margin-top: 30px">
          <NewArrivals />
        </section>

        <MainPageContent />
      </section>

      <section style="margin-top: 30px">
        <Partners />
      </section>
    </div>
  </section>
</template>

<script setup>
import HeaderCarousel from "../components/homepage/HeaderCarousel.vue";
import SmartCampaign from "../components/homepage/SmartCampaign.vue";
import PopularCategories from "../components/homepage/PopularCategories.vue";
import NewArrivals from "../components/homepage/NewArrivals.vue";
import MainPageContent from "../components/homepage/MainPageContent.vue";
import BlockLoader from "@/components/static/BlockLoader.vue";
import { useRoute } from "vue2-helpers/vue-router";
import { setCache } from "@/functions/affiliate";

import { defineAsyncComponent, onMounted } from "vue";

const route = useRoute();

onMounted(() => {
  if (route.fullPath.includes("?")) {
    const affiliateId = route.query.ref;
    setCache("affiliate", affiliateId);
  }
});

const Partners = defineAsyncComponent({
  loader: () => import("../components/homepage/Partners.vue"),
  loadingComponent: BlockLoader,
});
</script>

<style scoped>
.carr {
  height: 200px;
}
.hero-section .flashpick {
  width: 35%;
}
@media screen and (max-width: 769px) {
  .hero-section {
    flex-direction: column;
    height: min(35vh, 400px);
  }
  .hero-section .flashpick {
    /* width: 50%; */
    display: none;
  }
}
@media screen and (max-width: 900px) {
  .popular {
    flex-direction: column;
  }
  .arrival {
    width: 100%;
  }
}
@media screen and (max-width: 769px) {
  .carr {
    height: 300px;
    overflow: hidden;
  }
}

@media only screen and (max-width: 600px) {
  .container {
    padding: 0 !important;
  }
}

@media screen and (max-width: 579px) {
  .carr {
    height: 230px;
  }
}
</style>
