import Toastify from "toastify-js";
export function success(message) {
  Toastify({
    text: message || "Successful",
    duration: 3000,
    close: false,
    gravity: "top", // `top` or `bottom`
    position: "center", // `left`, `center` or `right`
    stopOnFocus: false, // Prevents dismissing of toast on hover
    style: {
      fontSize: "13px",
      borderRadius: "4px",
      background: "linear-gradient(to right, #333333, #333333)",
    },
  }).showToast();
}

export function fail(message) {
  Toastify({
    text: message || "An Error Occured",
    duration: 3000,
    close: false,
    gravity: "top", // `top` or `bottom`
    position: "center", // `left`, `center` or `right`
    stopOnFocus: false, // Prevents dismissing of toast on hover
    style: {
      fontSize: "13px",
      borderRadius: "4px",
      background: "linear-gradient(to right, #ff0000, #ff0000)",
    },
  }).showToast();
}
