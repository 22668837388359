<template>
  <div>
    <section style="margin-top: 30px">
      <BlockLoader v-if="isLoadingPageData" />
      <ForYou
        v-else
        :deals="pageData.top_deals"
        :refetch="refetchPageData"
        :isLoading="isLoadingPageData"
      />
    </section>

    <Banner />

    <section style="margin-top: 30px">
      <BlockLoader v-if="isLoadingPageData" />
      <BestSelling
        v-else
        :deals="pageData.best_selling_products"
        :refetch="refetchPageData"
        :isLoading="isLoadingPageData"
      />
    </section>

    <Banner />

    <section style="margin-top: 30px">
      <BlockLoader v-if="isLoadingPageData" />
      <TopDeals
        v-else
        :deals="pageData.top_deals"
        :refetch="refetchPageData"
        :isLoading="isLoadingPageData"
      />
    </section>

    <Banner />

    <section style="margin-top: 30px">
      <BlockLoader v-if="isLoadingPageData" />
      <PopularVendors
        v-else
        :deals="pageData.popular_vendors"
        :refetch="refetchPageData"
        :isLoading="isLoadingPageData"
      />
    </section>

    <Banner />

    <section style="margin-top: 30px">
      <RecentlyViewed />
    </section>
  </div>
</template>

<script setup>
import axios from "axios";
import ForYou from "./ForYou.vue";
import BestSelling from "./BestSelling.vue";
import TopDeals from "./TopDeals.vue";
import Banner from "@/components/static/Banner.vue";
import BlockLoader from "@/components/static/BlockLoader.vue";

import { defineAsyncComponent, ref, watchEffect } from "vue";
import { insertRandomly } from "@/functions/utils";
import { useQuery } from "@tanstack/vue-query";
import { fail } from "@/functions/toast";

const PopularVendors = defineAsyncComponent({
  loader: () => import("./PopularVendors.vue"),
  loadingComponent: BlockLoader,
});

const RecentlyViewed = defineAsyncComponent({
  loader: () => import("./RecentlyViewed.vue"),
  loadingComponent: BlockLoader,
});

const campaignData = ref(null);

const {
  data: pageData,
  refetch: refetchPageData,
  isLoading: isLoadingPageData,
} = useQuery({
  queryKey: ["dealstop"],
  queryFn: getPageData,
  retry: 1,
  keepPreviousData: true,
});

useQuery({
  queryKey: ["campaignData"],
  queryFn: getCampaingData,
  retry: 1,
  keepPreviousData: true,
});

async function getCampaingData() {
  try {
    const audienceResponse = await axios.get("campaigns/audiences/all");
    const audienceId = await audienceResponse.data.data[0].uuid;

    const campaignsResponse = await axios.get(`campaigns/${audienceId}`);
    const data = campaignsResponse.data.data;

    if (data.length) {
      campaignData.value = data;
    }

    return data;
  } catch (err) {
    // Report Errors to analytics
  }
}

async function getPageData() {
  try {
    const pageResponse = await axios.get("popular-stores-and-vendors");
    const pageData = await pageResponse.data.data;

    if (campaignData.value && pageData?.best_selling_products?.length) {
      pageData.best_selling_products = insertRandomly(
        pageData.best_selling_products,
        campaignData.value
      );
    }

    if (campaignData.value && pageData?.top_deals?.length) {
      pageData.top_deals = insertRandomly(
        pageData.top_deals,
        campaignData.value
      );
    }

    return pageData;
  } catch (err) {
    fail("An error occurred while fetching products!");
  }
}

watchEffect(() => {
  if (campaignData.value) {
    refetchPageData();
  }
});

window.hello = refetchPageData;
</script>
