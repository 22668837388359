<template>
  <div class="spinner" v-bind="$attrs" :style="{ borderColor: color }"></div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "white",
    },
  },
};
</script>

<style scoped>
.spinner {
  border: 1px solid;
  border-radius: 50%;
  border-bottom-color: transparent !important;
  width: 20px;
  height: 20px;
  animation-name: loading;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
