import api from "@/api";
import store from "@/store";

import { ref } from "vue";
import { useQuery } from "@tanstack/vue-query";
import { success, fail } from "@/functions/toast";

function useSavedItems() {
  const page = ref(1);
  const isDataLoaded = ref(false);
  const isDeleting = ref([]);
  const isSaving = ref([]);

  const getSavedItems = async () => {
    isDataLoaded.value = true;

    try {
      const res = await api.get(
        `products/saved?page=${page.value}&perPage=5&search`
      );

      store.commit("setSavedItems", res.data.data);
      return res.data;
    } finally {
      isDataLoaded.value = false;
    }
  };

  const { data, refetch, isLoading } = useQuery({
    queryKey: ["savedItems", page],
    queryFn: getSavedItems,
    keepPreviousData: true,
  });

  const setPage = (value) => {
    page.value = value;
  };

  const deleteSavedItem = async (value) => {
    isDeleting.value = [...isDeleting.value, value];

    try {
      await api.post(`products/saved/${value}/remove`);

      success("Item deleted");
      refetch();
    } catch (e) {
      fail(e.response.data.message);
    } finally {
      isDeleting.value = isDeleting.value.filter((id) => id !== value);
    }
  };

  // TODO: Refactor to service
  const saveProduct = async (value) => {
    if (window.sessionStorage.getItem("user") !== "authenticated") {
      store.state.AccessState = 1;
      return;
    }

    isSaving.value = [...isSaving.value, value];

    try {
      const response = await api.post(`/products/save/${value}`);

      if (response.data.data) {
        success("Saved item successfully");
        refetch();
      }
    } catch (e) {
      fail(e.response.data.message);
    } finally {
      isSaving.value = isSaving.value.filter((id) => id !== value);
    }
  };

  return {
    data,
    refetch,
    isLoading,
    page,
    increasePage: setPage,
    deleteSaved: deleteSavedItem,
    isDeleting,
    isSaving,
    saveProduct,
  };
}

export default useSavedItems;
