<template>
  <component :is="layout">
    <SideBar class="generalNav" v-if="showMenu" />
    <MobileNav class="generalNav" v-else />
    <slot />
  </component>
</template>

<script>
import SideBar from "@/modules/user-dashboard/components/static/userSideBar.vue";
import MobileNav from "./MobileNav.vue";
import store from "../store";
import axios from "axios";

import { useQuery } from "@tanstack/vue-query";

const defaultLayout = "defaultLayout";

const location = async () => {
  let res = await axios.get("ip-info");
  let userLocation = res.data.data;

  return userLocation;
};

export default {
  name: "AppLayout",
  components: { SideBar, MobileNav },
  data() {
    return {
      showMenu: true,
    };
  },
  computed: {
    layout() {
      const layout = this.$route.meta.layout || defaultLayout;
      return () => import(`@/layouts/${layout}.vue`);
    },
    menushow() {
      return window.addEventListener("resize", () => {
        if (window.innerWidth <= 990) {
          this.showMenu = false;
        } else if (window.innerWidth >= 990) {
          this.showMenu = true;
        }
      });
    },
  },
  mounted() {
    if (window.innerWidth <= 990) {
      this.showMenu = false;
    } else {
      this.showMenu = true;
    }
  },
  setup() {
    const locationQuery = useQuery({
      queryKey: ["location"],
      queryFn: location,
      retry: 1,
      keepPreviousData: true,
      staleTime: Infinity,
    });

    store.commit("setUserLocation", locationQuery.data);
  },
};
</script>

<style scoped>
.generalNav {
  display: none;
}
@media screen and (max-width: 990px) {
  .generalNav {
    display: block;
  }
}
</style>
