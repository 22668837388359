<template>
  <button
    type="button"
    :disabled="disabled || loading"
    class="primary-btn"
    v-bind="$attrs"
  >
    <div v-if="loading">
      <Spinner class="mr-3" />
    </div>
    <div>
      <slot></slot>
    </div>
  </button>
</template>

<script>
import Spinner from "../../layouts/spinner.vue";

export default {
  components: { Spinner },
  name: "WalletButton",
  props: {
    type: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.primary-btn[disabled] {
  filter: opacity(0.5);
}
.primary-btn {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  padding: 1.5rem 2rem;
  background-color: #f9b00a;
  text-align: center;
  font-size: Max(1rem, 16px);
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0em;
  color: #fff;
  text-align: center;
  outline: none;
  margin-top: 1rem;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.3s ease-in-out;
}
</style>
