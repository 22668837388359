import { render, staticRenderFns } from "./SaveOn.vue?vue&type=template&id=933f63e0&scoped=true"
import script from "./SaveOn.vue?vue&type=script&setup=true&lang=js"
export * from "./SaveOn.vue?vue&type=script&setup=true&lang=js"
import style0 from "./SaveOn.vue?vue&type=style&index=0&id=933f63e0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "933f63e0",
  null
  
)

export default component.exports