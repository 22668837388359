export default {
  mounted() {
    const siteKey = process.env.VUE_APP_SITE_KEY;
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=${siteKey}`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  },
};
