<template>
  <section
    class="cookie"
    :class="{ active: cookie }"
    v-if="$store.state.showCover"
  >
    <div v-if="steps === 1">
      <img
        src="@/assets/img/cookie.svg"
        loading="lazy"
        height="32"
        width="32"
        alt=""
      />
      <div class="cookie__text">
        <p>
          We use cookies to ensure that we offer you the best experience while
          browsing our website. Check our <span>Cookie Policy</span> for more
          information.
        </p>
        <div class="buttons">
          <button @click="steps = 2">Manage Cookies</button>
          <button class="accept" @click="(cookie = !cookie), (steps = 0)">
            Accept
          </button>
        </div>
      </div>
    </div>
    <div v-if="steps === 2" class="cookie__manage">
      <h4>Manage Cookies</h4>
      <p>
        The website uses cookies to improve your experience while you navigate
        through the website. Out of these, the cookies that are categorized are
        stored on your browser as they are essential for the working of basic
        functionalities of the...
      </p>
      <h6>Show more</h6>
      <div class="cookie__manage__cards">
        <div class="item" v-for="item in manage" :key="item.id">
          <div class="item__top" @click.self="showDetails = item.id">
            <span>
              <img
                src="@/assets/icons/arr-right.svg"
                loading="lazy"
                alt=""
                width="18"
                height="18"
              />
              {{ item.main }}
            </span>
            <span>
              <p v-if="item.enabled">Enabled</p>
              <p v-if="!item.enabled">Disabled</p>
              <input
                type="checkbox"
                :id="item.main"
                class="checkbox"
                :checked="item.enabled"
                @click.stop="toggle(item.id)"
              />
              <label :for="item.main" class="switch"></label>
            </span>
          </div>
          <div class="item__bottom" v-if="item.id === showDetails">
            {{ item.desc }}
          </div>
        </div>
      </div>
      <button
        type="button"
        class="manage"
        @click="(cookie = !cookie), (steps = 0)"
      >
        Save & Accept
      </button>
    </div>
  </section>
</template>

<script setup>
import { onMounted, ref } from "vue";
import store from "@/store";
const cookie = ref(false);
const steps = ref(1);
const showDetails = ref(0);
const manage = ref([
  {
    id: 1,
    main: "Necessary",
    desc: "Our website uses necessary cookies to ensure the proper functioning and security of the website. These cookies are essential and cannot be disabled. By continuing to use our site, you consent to our use of necessary cookies.",
    enabled: false,
  },
  {
    id: 2,
    main: "Functional",
    desc: "Our website uses functional cookies to enhance your browsing experience by remembering your preferences and settings.",
    enabled: false,
  },
  {
    id: 3,
    main: "Performance",
    desc: "Performance cookies are used to understand and analyze the key performance indexes of the website which helps in delivering a better user experience to the visitors.",
    enabled: false,
  },
  {
    id: 4,
    main: "Analytics",
    desc: "Our website uses analytics cookies to collect information about how you use our site, which helps us improve its performance and functionality",
    enabled: false,
  },
  {
    id: 5,
    main: "Advertisement",
    desc: "Our website uses advertisement cookies to display personalized ads and measure the effectiveness of our marketing campaigns. These cookies collect information about your browsing habits and interests to provide you with relevant advertising content.",
    enabled: false,
  },
  {
    id: 6,
    main: "Others",
    desc: "Our website uses other types of cookies to enhance your browsing experience and provide additional features and functionalities. These cookies may collect information about your device, location, and online behavior to support various services and integrations.",
    enabled: false,
  },
]);
onMounted(() => {
  setTimeout(() => {
    cookie.value = true;
  }, 600);
});
if (window.sessionStorage.getItem("loaded") === null) {
  window.sessionStorage.setItem("loaded", true);
} else {
  store.state.showCover = false;
}
const toggle = (value) => {
  manage.value.map((item) => {
    if (value === item.id) {
      item.enabled = !item.enabled;
    }
  });
};
</script>

<style scoped>
.cookie {
  background-color: white;
  width: Min(582px, 95%);
  max-height: 60vh;
  height: auto;
  overflow: auto;
  padding: 1rem 1.5rem;
  border-radius: 10px;
  position: fixed;
  z-index: 10;
  bottom: 4%;
  right: 2%;
  transform: translateY(2vh);
  opacity: 0;
  transition: all 1s ease-in-out;
  display: flex;
  gap: 1rem;
  border-radius: 20px;
}
.active {
  opacity: 1;
  transition: all 1s ease-in-out;
  transform: translateY(0);
}
.cookie p {
  font-family: "Inter", sans-serif;
  font-size: Max(1rem, 16px);
  font-weight: 500;
  line-height: 170%;
  letter-spacing: 0em;
  text-align: left;
}
.cookie p span {
  color: #338b75;
  cursor: pointer;
}
.buttons {
  margin-top: 1rem;
  display: flex;
  gap: 1.5rem;
}
button {
  height: 38px;
  padding: 0 13px;
  gap: 8px;
  border-radius: 20px;
  outline: none;
  border: none;
  background-color: #f3f3f3;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
}
.accept {
  background-color: #338b75;
  color: white;
  min-width: 91px;
}
.manage {
  background-color: #338b75;
  color: white;
  min-width: 186px;
  height: 56px;
  border-radius: 8px;
  margin-left: auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}
.item {
  margin: 0.7rem 0;
}
.item__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  background: #f0f0f0;
  padding: 1rem;
}
.item__top span {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.item__bottom {
  padding: 1rem;
}
.cookie__manage h4 {
  font-family: THICCCBOI;
  font-size: Max(1.5rem, 16px);
  font-weight: 500;
  line-height: 120%;
  text-align: left;
  margin-bottom: 2rem !important;
}
.cookie__manage p {
  font-family: THICCCBOI;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 140%;
  text-align: left;
}
.cookie__manage h6 {
  font-family: THICCCBOI;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  text-align: left;
  color: #646464;
  margin: 2rem 0 !important;
}
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  transition: all 0.3s;
  cursor: pointer;
}
.switch::after {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: white;
  top: 1px;
  left: 1px;
  transition: all 0.3s;
}

.checkbox:checked + .switch::after {
  left: 20px;
}
.checkbox:checked + .switch {
  background-color: #7983ff;
}
.checkbox {
  display: none;
}
</style>
