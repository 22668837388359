<template>
  <div class="my-4">
    <div style="height: 400px" v-if="data && data.length">
      <div v-campaign-insights:views="CampaignImage.uuid">
        <div
          class="banner-img banner-big wow fadeIn animated f-none animated animated"
          style="visibility: visible"
          v-campaign-insights:views="CampaignImage.uuid"
        >
          <img
            :src="CampaignImage.campaign_image"
            alt="campaign baner image"
            fetchpriority="high"
            class="w-full"
          />
          <div class="banner-text">
            <h4 class="mb-15 mt-40 text-primary">{{ CampaignImage.name }}</h4>
            <h1 class="fw-600 mb-20" v-html="CampaignImage.caption"></h1>
            <a :href="CampaignImage.url" target="_blank">
              Get in touch <i class="fi-rs-arrow-right"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div
      class="banner-img banner-big wow fadeIn animated f-none animated animated"
      style="visibility: visible"
      v-else
    >
      <img
        src="https://res.cloudinary.com/dzkh9cyq4/image/upload/q_auto:low,f_auto/v1719308350/Servitor_Website_rf0r0q.jpg"
        alt="campaign baner image"
        height="270"
        fetchpriority="high"
      />
      <div class="banner-text">
        <h4 class="mb-15 mt-40 text-primary">Services</h4>
        <h1 class="fw-600 mb-20">Showcase Your Service <br />Get Noticed!</h1>
        <a href="https://vendors.sellersmart.ng/register" target="_blank">
          Get Started <i class="fi-rs-arrow-right"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watchEffect } from "vue";
import { useQuery } from "@tanstack/vue-query";
import axios from "axios";

const CampaignImage = ref(null);

const carouselCampaign = async () => {
  let res = await axios.get("carousel-campaigns");
  return res.data.data;
};

const { data } = useQuery({
  queryKey: ["userthirdCampaigns"],
  queryFn: carouselCampaign,
});

// Function to get two random, different items from an array
const getRandomItems = (array) => {
  if (data.value.length < 1) return [data.value[0] || null, null];

  const firstIndex = Math.floor(Math.random() * data.value.length);
  return [array[firstIndex]];
};

watchEffect(() => {
  if (data.value && data.value.length > 1) {
    const [first] = getRandomItems(data.value);
    CampaignImage.value = first;
  } else {
    CampaignImage.value = null;
  }
});

setInterval(() => {
  if (data.value && data.value.length > 1) {
    const [first] = getRandomItems(data.value);
    CampaignImage.value = first;
  } else {
    CampaignImage.value = null;
  }
}, 30000);
</script>

<style scoped>
.banner-img {
  position: relative;
  clear: both;
  height: auto;
}

.banner-img img {
  width: 100%;
  height: 100%;
  min-height: 150px;
  object-fit: cover;
}
.banner-img::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
}
.banner-img .banner-text {
  position: absolute;
  top: 50%;
  z-index: 2;
  -webkit-transform: translateY(-48%);
  transform: translateY(-48%);
  padding: 1rem 1.5rem;
}
.banner-img .banner-text h4 {
  font-size: 13px;
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
  color: white;
}
.banner-img .banner-text h1 {
  font-size: 16px;
  font-weight: 800;
  color: white;
}
.banner-img .banner-text a {
  display: flex;
  align-items: self-start;
  column-gap: 0.25rem;
  font-size: 13px;
  font-weight: 600;
  color: white;
  margin-top: 0.25rem;
}
@media (min-width: 768px) {
  .banner-img .banner-text h4 {
    font-size: 16px;
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .banner-img .banner-text {
    padding: 1rem 2rem;
  }
  .banner-img .banner-text h1 {
    font-size: 1.6rem;
  }
  .banner-img .banner-text a {
    font-size: 16px;
    margin-top: 0.5rem;
  }
}
@media (min-width: 1024px) {
  .banner-img .banner-text {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 20px 30px;
  }
  .banner-img .banner-text h4 {
    font-size: 18px;
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .banner-img .banner-text {
    padding: 2.5rem 3rem;
  }
  .banner-img .banner-text h1 {
    font-size: 2.5rem;
  }
  .banner-img .banner-text a {
    font-size: 18px;
    margin-top: 1rem;
  }
}
</style>
