import store from "../store";
import api from "@/api.js";
import { success, fail } from "./toast.js";
import { createPopper } from "@popperjs/core";
import {
  SET_CART_AMOUNT,
  SET_CART_ITEMS,
  SET_IS_LOADING_CART,
} from "@/modules/cart/store/index.js";

// TODO: Refactor cart methods to services
export async function addToCart(payload) {
  try {
    await api.post("cart/add", payload);

    getCartItems();

    success("Item added to cart");
  } catch (e) {
    fail(e.response.data.message);
  }
}

export async function addSaveProduct(payload) {
  if (window.sessionStorage.getItem("user") !== "authenticated") {
    store.state.AccessState = 1;
    return;
  }

  try {
    const response = await api.post(`/products/save/${payload}`);

    success("Saved item successfully");

    store.commit("setSaveItem", payload, response.data.data);
  } catch (e) {
    fail(e.response.data.message);
  }
}

export async function getCartItems() {
  if (store.state.cartModule.isLoadingCart) return;

  store.commit(SET_IS_LOADING_CART, true);

  try {
    const res = await api.get("cart/items");

    store.commit(SET_CART_ITEMS, res.data.data.cart_items);
    store.commit(SET_CART_AMOUNT, res.data.data.total_amount_to_be_paid);

    return res.data.data;
  } catch (err) {
    console.log(err);
    fail("An error occurred while fetching cart!");
  } finally {
    store.commit(SET_IS_LOADING_CART, false);
  }
}

export async function upsertCartItemVariant(productId, ...variants) {
  if (!variants.length) return;

  // Get item variants
  const cartItems = store.state.cartModule.items || {};
  const itemVariants = {};

  for (let id in cartItems) {
    if (id.includes(productId)) {
      itemVariants[id] = cartItems[id];
    }
  }

  // Check item exists
  variants.forEach((variant) => {
    itemVariants[variant.id] = variant;
  });

  const variantsList = Object.values(itemVariants).filter(
    (item) => item.quantity
  );

  if (!variantsList.length) {
    deleteCartItem(variants[0].uuid);

    return;
  }

  const payload = { variant: variantsList };
  store.commit(SET_IS_LOADING_CART, true);

  try {
    await api.put(`cart/${variants[0].uuid}/product-quantity`, payload);

    success("Item saved successfully");

    return true;
  } catch (e) {
    fail(e.response?.data?.message || "An error occurred while saving updates");

    return false;
  } finally {
    store.commit(SET_IS_LOADING_CART, false);

    getCartItems();
  }
}

export async function deleteCartItem(itemId) {
  store.commit(SET_IS_LOADING_CART, true);

  try {
    await api.delete(`cart/items/${itemId}`);

    success("Item deleted successfully!");
  } catch (e) {
    fail(e.response.data.message);
  } finally {
    store.commit(SET_IS_LOADING_CART, false);

    getCartItems();
  }
}

export function formatPrice(price, style = "") {
  const options = {
    currency: "NGN",
    style: "currency",
    currencyDisplay: "code",
  };

  if (style) options.style = style;

  return new Intl.NumberFormat("en-US", options).format(price);
}

export function formatNumber(price) {
  const options = {
    notation: "standard",
  };

  return new Intl.NumberFormat("en-US", options).format(price);
}

export function withPopper(dropdownList, component, { width }) {
  dropdownList.style.width = width;

  const popper = createPopper(component.$refs.toggle, dropdownList, {
    placement: "bottom",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, -1],
        },
      },
      {
        name: "toggleClass",
        enabled: true,
        phase: "write",
        fn({ state }) {
          component.$el.classList.toggle("drop-up", state.placement === "top");
        },
      },
    ],
  });

  return () => popper.destroy();
}

export function variantsAsCartItems(items) {
  const variants = items.reduce((accumulator, item) => {
    const itemVariants =
      item?.variant?.map((variant) => {
        const itemSnapshot = { ...item, ...variant };
        let id = itemSnapshot.product.uuid;

        if (variant.colors) {
          id = `${id}+colors:${variant.colors}`;
        }

        if (variant.sizes) {
          id = `${id}+sizes:${variant.sizes}`;
        }

        itemSnapshot._id = itemSnapshot.id;
        itemSnapshot.id = id;

        delete itemSnapshot.variant;

        return itemSnapshot;
      }) || [];

    // Backward Compatibility 🤧
    if (!item.variant && item.quantity > 0) {
      const itemSnapshot = { ...item };
      let id = itemSnapshot.product.uuid;

      itemSnapshot._id = itemSnapshot.id;
      itemSnapshot.id = id;

      delete itemSnapshot.variant;

      itemVariants.push(itemSnapshot);
    }

    return [...accumulator, ...itemVariants];
  }, []);

  return variants;
}

// Function to shuffle array
export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }

  return array;
}

export function insertRandomly(destination = [], items = []) {
  const result = [...destination];

  items.forEach((item) => {
    const randomIndex = Math.floor(Math.random() * (result.length + 1));
    result.splice(randomIndex, 0, item);
  });

  return result;
}

export async function getVisitorsJwt() {
  try {
    const response = await api.post("generate-jwt");

    const data = JSON.stringify(response.data.data);

    window.localStorage.setItem("jwt", data);
  } catch (err) {
    // Log Error
  }
}
