import Vue from "vue";

Vue.directive("auto-dimensions", {
  inserted: (el) => {
    if (el.tagName === "IMG") {
      el.addEventListener("load", function () {
        if (!el.getAttribute("width") && !el.getAttribute("height")) {
          el.setAttribute("width", this.naturalWidth);
          el.setAttribute("height", this.naturalHeight);
        }
      });
    }
  },
});
