<template>
  <div class="input-qty d-flex align-items-center">
    <span role="button" @click.stop="onDecrease">
      <i-icon icon="ic:twotone-minus" />
    </span>
    <input
      type="number"
      v-model="quantity"
      v-on:input="onChangeHandler"
      :max="props.max"
      :min="props.min"
      :aria-valuemax="props.max"
      aria-valuemin="1"
    />
    <span role="button" @click.stop="onIncrease">
      <i-icon icon="ic:baseline-plus" />
    </span>
  </div>
</template>

<script setup>
import { defineProps, ref, watch } from "vue";
import { fail } from "@/functions/toast";

const props = defineProps({
  onChange: {
    type: Function,
    default: () => {},
  },
  value: {
    type: Number,
    default: 0,
  },
  max: {
    type: Number,
    default: 10,
  },
  min: {
    type: Number,
    default: 1,
  },
});

const quantity = ref(props.value);

let saveQuantityTimer = null;

const saveQuantityHandler = () => {
  if (saveQuantityTimer) {
    clearTimeout(saveQuantityTimer);
  }

  saveQuantityTimer = setTimeout(async () => {
    props.onChange(quantity.value);
  }, 500);
};

const onChangeHandler = (event) => {
  const value = parseInt(event.target.value);

  if (!value && value !== 0) return;

  if (value >= props.min && value <= props.max) {
    quantity.value = value;

    saveQuantityHandler();
  } else {
    fail(
      `Quantity must be greater than ${props.min} or lesser than ${props.max}`
    );
  }
};

const onIncrease = () => {
  if (quantity.value !== props.max) {
    quantity.value++;

    saveQuantityHandler();
  }
};

const onDecrease = () => {
  if (quantity.value > props.min) {
    quantity.value--;

    saveQuantityHandler();
  }
};

watch(
  () => props.value,
  (newValue) => {
    console.log("QUANTITY CHANGED", newValue);
    quantity.value = newValue;
  }
);
</script>
