<template>
  <section>
    <div v-if="isLoading" class="containr px-1">
      <SlickCarousel v-bind="settings"
        ><blockShimmer
          height="266px"
          width="150px"
          style="--shimmer-color: red; --shimmer-shine-color: red"
          v-for="i in 8"
          :key="i"
      /></SlickCarousel>
    </div>
    <!-- <div v-if="isLoading">
      <sentencesShimmer
        height="266px"
        width="150px"
        style="--shimmer-color: #d7d5d1; --shimmer-shine-color: #d7d5d1"
      />
    </div> -->
    <div class="best-selling" v-else>
      <!-- <shimmerCard /> -->
      <div class="d-flex justify-content-between align-items-center mb-4">
        <h6 class="header-text section" style="margin-bottom: 0 !important">
          Best Selling
        </h6>
        <router-link class="primary-link" to="/products?filter=best_selling"
          >See all</router-link
        >
      </div>
      <div class="containr px-1">
        <SlickCarousel v-bind="settings">
          <ProductCard
            v-for="item in deals"
            :item="item"
            :refetch="refetch"
            :key="item"
            class="mr-3"
          />
        </SlickCarousel>
      </div>
    </div>
  </section>
</template>

<script>
import ProductCard from "@/components/cards/ProductCard.vue";
// import { useQuery } from "@tanstack/vue-query";
// import axios from "axios";
import { blockShimmer } from "vue-shimmer";
// import shimmerCard from "@/components/cards/shimmerCard.vue";

export default {
  components: {
    ProductCard,
    blockShimmer,
    // shimmerCard,
  },
  props: ["deals", "refetch", "isLoading"],
  data() {
    return {
      settings: {
        // dots: true,
        autoplay: true,
        focusOnSelect: true,
        infinite: true,
        speed: 800,
        slidesToShow: 5,
        slidesToScroll: 1,
        touchThreshold: 5,
        centerMode: false,
        centerPadding: "12px",
        lazyLoad: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  setup() {
    // const bestSelling = async () => {
    //   let res = await axios.get("popular-stores-and-vendors");
    //   return res.data.data.best_selling_products;
    // };
    // const { data, refetch, isLoading } = useQuery({
    //   queryKey: ["bestselling"],
    //   queryFn: bestSelling,
    //   retry: 1,
    //   keepPreviousData: true,
    // });
    // return { data, refetch, isLoading };
  },
};
</script>

<style scoped>
:deep(.shimmer) {
  background: #d7d5d1;
}
</style>
