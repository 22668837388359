<template>
  <div
    class="user__side__bar"
    :class="{ user__side__bar__active: $store.state.menuState }"
  >
    <a href="/" style="display: none" ref="dash"></a>
    <ul class="mb-0">
      <li
        v-for="item in menu"
        :key="item.id"
        :class="{ hidden: item.id === 11 }"
      >
        <a
          role="button"
          @click="goToMenu(item)"
          class="d-flex align-items-center"
          style="gap: 10px"
          :class="
            routeName.parent === item.parent ? 'router-link-exact-active' : ''
          "
        >
          <div>
            <i-icon
              width="15"
              height="15"
              class="user-icon"
              :icon="item.icon"
            />
          </div>
          <div class="d-flex justify-content-between align-items-center w-100">
            <div class="d-flex" style="gap: 4px">
              <span> {{ item.title }} </span>
              <!-- <span class="notification-dot" v-if="item.hasNotification"></span> -->
            </div>
            <i
              v-if="item.hasArrow"
              class="isax"
              :class="
                routeName.parent === item.parent
                  ? 'isax-arrow-right-3'
                  : 'isax-arrow-down-1'
              "
              style="font-size: 11px; font-weight: 300"
            ></i>
          </div>
        </a>

        <!-- More Options -->
        <transition name="fade">
          <ul class="expanded" v-if="expanded">
            <li
              v-for="dataItem in item.children"
              :key="dataItem.id"
              @click="handleMenu"
            >
              <router-link :to="dataItem.url" @click="handleMenu">
                <span class="d-flex align-items-center ml-4" style="gap: 5px">
                  <i-icon
                    :icon="
                      dataItem.name === routeName.subName
                        ? 'mdi:radiobox-marked'
                        : 'mdi:radiobox-blank'
                    "
                    width="20px"
                  />
                  <span>{{ dataItem.title }}</span>
                </span>
              </router-link>
            </li>
          </ul>
        </transition>
      </li>
    </ul>
  </div>
</template>

<script>
import menu from "@/Data/userMenu";
export default {
  data() {
    return {
      menu: menu,
      activeName: "1",
      expanded: false,
    };
  },
  methods: {
    goToMenu(item) {
      if (item.title === "Logout") {
        this.$store.state.logout = true;
      } else {
        this.menu.map((menuItem) => {
          if (menuItem.id === item.id) {
            if (item.hasChildren) {
              this.showOptions();
            } else {
              this.$router.push("/account" + item.url);
              this.$store.dispatch("handleMenu");
            }
          }
        });
      }
      // item.hasChildren
      //   ? this.showOptions()
      //   : this.$router.push("/account" + item.url);
    },

    showOptions() {
      this.expanded = !this.expanded;
    },
    handleMenu() {
      this.$store.dispatch("handleMenu");
    },
  },
  computed: {
    routeName() {
      return this.$route.meta;
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.hidden {
  display: none;
}
@media screen and (max-width: 990px) {
  .user__side__bar {
    overflow: auto;
    max-width: 100vw;
    top: 0;
    right: 0;
    width: 300px;
    height: 100vh;
    transform: translateX(100%);
    top: 0;
    position: fixed;
    z-index: 3;
    transition: all 0.5s ease-in-out;
    background-color: var(--light-accent);
    padding-top: 2rem;
  }
  .user__side__bar__active {
    transform: unset;
  }
  .hidden {
    display: block;
  }
}
</style>
