// cacheUtils.js
const CACHE_PREFIX = "vue-app-cache-";

export function setCache(key, value, ttl = 7 * 24 * 60 * 60 * 1000) {
  const now = new Date();
  const item = {
    value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(`${CACHE_PREFIX}${key}`, JSON.stringify(item));
}

export function getCache(key) {
  const itemStr = localStorage.getItem(`${CACHE_PREFIX}${key}`);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(`${CACHE_PREFIX}${key}`);
    return null;
  }
  return item.value;
}
