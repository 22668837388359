<template>
  <section class="popup">
    <a href="/account" style="display: none" ref="dash"></a>
    <div class="popup__item">
      <div class="popup__header">
        <h6>Log In</h6>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          @click="$store.state.AccessState = null"
        >
          <path
            d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
            fill="black"
          />
        </svg>
      </div>
      <div class="popup__content">
        <form action="" @submit.prevent="handleSubmit">
          <div>
            <label for="">Email address or Phone number</label>
            <input
              type="text"
              placeholder="Email address or phone number"
              v-model="userInfo.email"
              name="email"
              v-validate="'required|email'"
              :class="{ 'border-danger': errors.has('email') }"
            />
            <ValidationError
              v-show="errors.has('email')"
              :text="errors.first('email')"
            />
          </div>

          <div>
            <label for="">Password</label>
            <div class="pword_input">
              <input
                :type="visibled ? 'text' : 'password'"
                placeholder="Password"
                name="password"
                v-model="userInfo.password"
                v-validate="'required'"
                :class="{ 'border-danger': errors.has('password') }"
              />
              <span v-if="visibled" @click="toggleVisiblity" class="corner">
                <i class="fa-regular fa-eye"></i>
              </span>
              <span v-else @click="toggleVisiblity" class="corner"
                ><i class="fa-regular fa-eye-slash"></i
              ></span>
            </div>
            <ValidationError
              v-show="errors.has('password')"
              :text="errors.first('password')"
            />
          </div>
          <div class="remember">
            <span>
              <input type="checkbox" />
              Remember me
            </span>
            <p @click="$store.state.AccessState = 3">Forgot password?</p>
          </div>
          <PrimaryButton
            :loading="loading"
            :disabled="loading"
            :class="{ Loading: loading }"
          >
            Sign In
          </PrimaryButton>
        </form>
        <!-- <button class="google">Continue with Google</button> -->
        <h6 class="new__account" @click="$store.state.AccessState = 2">
          Don’t have an account? Sign Up
        </h6>
      </div>
    </div>
  </section>
</template>

<script>
import api from "@/api";
import Recaptcha from "@/functions/captcha.mixin";

import { mapGetters } from "vuex";
import { getCartItems } from "@/functions/utils";
import { fail, success } from "@/functions/toast";
import { SET_CART_AMOUNT, SET_CART_ITEMS } from "@/modules/cart/store";

export default {
  mixins: [Recaptcha],
  comments: {},
  data() {
    return {
      loading: false,
      visibled: false,
      userInfo: {
        email: "",
        password: "",
        recaptcha: "",
      },
    };
  },
  computed: {
    ...mapGetters("cartModule", ["cartItemsCount"]),
  },
  methods: {
    async handleSubmit() {
      // eslint-disable-next-line no-undef
      const captcha = await grecaptcha.enterprise.execute(
        process.env.VUE_APP_SITE_KEY,
        { action: "LOGIN" }
      );
      this.userInfo.recaptcha = captcha;
      await this.$validator.validateAll().then(async (result) => {
        if (result) {
          this.loading = true;
          try {
            const route = this.$route.query.redirectFrom || "/account";

            await api.post("login", this.userInfo);
            const req = await api.get("user/profile");

            this.loading = false;
            this.$store.state.AccessState = 0;
            this.$store.state.userDetails = req.data.data;

            window.sessionStorage.setItem(
              "userDetails",
              JSON.stringify(req.data.data)
            );

            if (this.cartItemsCount > 0) {
              const token = JSON.parse(window.localStorage.getItem("jwt"));

              const updatedHeaders = {
                "X-SellersMart-JWT": token.token,
                "X-SellersMart-JWT-passphrase": token.passphrase,
              };

              const updatedResponse = await api.put(
                "cart/update-user-details",
                null,
                { headers: updatedHeaders }
              );

              this.$store.commit(
                SET_CART_ITEMS,
                updatedResponse.data.data.cart_items
              );
              this.$store.commit(
                SET_CART_AMOUNT,
                updatedResponse.data.data.total_amount_to_be_paid
              );
            } else {
              getCartItems();
            }

            success("Log in successful");

            this.$router.push(route);
          } catch (e) {
            fail(e.response.data.message);
            this.loading = false;
          }
        }
      });
    },
    toggleVisiblity() {
      this.visibled = !this.visibled;
    },
  },
};
</script>

<style scoped>
.popup {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10000000;
}
button[disabled] {
  filter: opacity(0.5);
}
.pword_input {
  position: relative;
}
.pword_input input {
  position: relative;
}
.corner {
  position: absolute;
  right: 5%;
  top: 30%;
  cursor: pointer;
}
.popup__item {
  width: Min(90%, 444px);
  min-height: 300px;
  padding: 2rem 2rem !important;
  border: 0.5px;
  background-color: #ffffff;
  padding: 1rem;
  position: absolute;
  z-index: 15;
  border-radius: 1rem;
}
.popup__item::-webkit-scrollbar {
  display: none;
}
.popup__header {
  display: flex;
  justify-content: space-between;
}
.popup__header h6 {
  font-size: Max(1.5rem, 16px);
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0em;
  color: #002e22;
}
.popup__header svg {
  cursor: pointer;
}
.remember {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}
.remember span {
  display: flex;
  align-items: center;
  gap: 0.35rem;
  font-size: 14px;
}
.remember span input {
  width: 14px;
  height: 14px;
  cursor: pointer;
}
.remember p {
  margin-bottom: unset;
  color: #006e52;
  font-size: 14px;
  letter-spacing: 0em;
  text-decoration: underline;
  cursor: pointer;
}
.remember p:hover {
  text-decoration: none;
}
label {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: #434343;
  margin-top: 1rem;
}
input {
  width: 100%;
  height: 48px;
  padding: 0.75rem;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  outline: none;
  font-size: Max(1rem, 16px);
  font-weight: 400;
  line-height: 175%;
  letter-spacing: 0em;
  color: rgba(69, 69, 69);
}
input::placeholder {
  font-size: Max(1rem, 16px);
  font-weight: 400;
  line-height: 175%;
  letter-spacing: 0em;
  color: rgba(69, 69, 69, 0.75);
}
button {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  padding: 1.5rem 2rem;
  background-color: #006e52;
  text-align: center;
  font-size: Max(1rem, 16px);
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0em;
  color: white;
  text-align: center;
  outline: none;
  margin-top: 1rem;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.3s ease-in-out;
  margin-bottom: 1.5rem;
}
button[type="submit"]:hover {
  background-color: #01553f;
}
.google {
  background-color: transparent;
  border: 1px solid #006e52;
  color: #006e52;
  background-image: url("@/assets/icons/google.svg");
  background-repeat: no-repeat;
  background-position: 10% center;
  margin-bottom: 3rem;
}
.new__account {
  color: #006e52;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
}
</style>
